import { BaseController, EventBus } from "@stimulus-library/utilities";
import { extractPredicates } from "./expressions";
import { signalConnectEvent, signalValueEvent } from "./events";
import { useEventBus } from "@stimulus-library/mixins";
export class SignalBaseController extends BaseController {
    static values = {
        name: String,
    };
    connect() {
        EventBus.emit(signalConnectEvent(this.nameValue));
        useEventBus(this, signalValueEvent(this.nameValue), this._onSignal);
    }
    get predicateString() {
        return "";
    }
    get _predicates() {
        return extractPredicates(this.predicateString);
    }
    allPredicatesMatch(value) {
        return this._predicates.every(predicate => predicate(value));
    }
}
