import { signalVisibilityEvent } from "./events";
import { SignalBaseController } from "./base_controller";
import { installClassMethods } from "@stimulus-library/mixins";
export class SignalVisibilityController extends SignalBaseController {
    static values = {
        name: String,
        show: String,
    };
    static classes = ["hide"];
    get defaultHideClasses() {
        return ["hide"];
    }
    get predicateString() {
        return this.showValue;
    }
    connect() {
        super.connect();
        installClassMethods(this);
    }
    _onSignal(payload) {
        const value = payload.value;
        if (this.showValue == "default") {
            if (value == "") {
                this.removeHideClasses(this.el);
            }
            else {
                this.addHideClasses(this.el);
            }
            return;
        }
        if (this.allPredicatesMatch(value)) {
            this.dispatchEvent(this.el, signalVisibilityEvent(this.nameValue, "show"), { detail: { predicate: this.showValue, value } });
            this.removeHideClasses(this.el);
        }
        else {
            this.dispatchEvent(this.el, signalVisibilityEvent(this.nameValue, "hide"), { detail: { predicate: this.showValue, value } });
            this.addHideClasses(this.el);
        }
    }
}
