import { BaseController, dispatchEvent } from "@stimulus-library/utilities";
import { useIntersection } from "@stimulus-library/mixins";
export class IntersectionController extends BaseController {
    static values = { threshold: String };
    get _threshold() {
        if (this.hasThresholdValue) {
            return this.thresholdValue.split(",").map(val => Number.parseFloat(val.trim())).filter(val => val >= 0 && val <= 1);
        }
        else {
            return [0, 1];
        }
    }
    connect() {
        useIntersection(this, this.el, this.appear, this.disappear, { threshold: this._threshold });
    }
    // eslint-disable-next-line  @typescript-eslint/no-unused-vars
    appear(entry) {
        dispatchEvent(this, this.el, this.eventName("appear"));
    }
    // eslint-disable-next-line  @typescript-eslint/no-unused-vars
    disappear(entry) {
        dispatchEvent(this, this.el, this.eventName("disappear"));
    }
}
