import { BaseController, requestSubmit } from "@stimulus-library/utilities";
import { useEventListener } from "@stimulus-library/mixins";
export class AutoSubmitFormController extends BaseController {
    static values = { submitMode: String, eventMode: String, debounceInterval: Number };
    get _eventModes() {
        if (this.hasEventModeValue) {
            const modes = this.eventModeValue.split(" ").map(mode => mode.trim());
            if (modes.length === 1 && modes[0] === "debounced") {
                return ["change", "input"];
            }
            if (!modes.every(mode => ["change", "input"].includes(mode))) {
                throw new Error(`The modeValue provided '${this.eventModeValue}' is not one of the recognised configuration options`);
            }
            return modes;
        }
        else {
            return ["change"];
        }
    }
    get _debounceTimeout() {
        return this.hasDebounceIntervalValue ? this.debounceIntervalValue : -1;
    }
    get _mode() {
        if (this.hasSubmitModeValue) {
            if (!["direct", "request"].includes(this.submitModeValue)) {
                throw new Error(`The modeValue provided '${this.submitModeValue}' is not one of the recognised configuration options`);
            }
            return this.submitModeValue;
        }
        else {
            return "request";
        }
    }
    get _cssSelector() {
        const inputTypes = ["input", "textarea", "select"];
        const ignore = ":not([data-no-autosubmit])";
        return inputTypes.map(type => type.concat(ignore)).join(",");
    }
    get inputElements() {
        let subElements = Array.from(this.element.querySelectorAll(this._cssSelector));
        subElements = subElements.filter(el => !this._ancestorIsTrix(el));
        return subElements;
    }
    connect() {
        this.inputElements.forEach(el => {
            return useEventListener(this, el, this._eventModes, this.submit, { debounce: this._debounceTimeout && this._debounceTimeout > 0 ? this._debounceTimeout : undefined });
        });
    }
    _ancestorIsTrix(element) {
        return element.closest("trix-toolbar") !== null && element.closest("trix-editor") !== null;
    }
    submit() {
        const el = this.el;
        if (this._mode == "request") {
            requestSubmit(el);
        }
        else {
            el.submit();
        }
    }
}
